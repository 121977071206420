'use client';

import { useState, useCallback, useTransition } from 'react';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import mutate from '@/lib/sanity/mutate';
import useAuth from '@/hooks/useAuth';
import useNotification from '@/hooks/useNotification';
import getUID from '@/utils/getUID';

interface AddQuoteProps {
  getItems: () => void;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  handleAddToQuote?: (quoteId: string) => void;
}

export default function AddQuote({ getItems, setSelectedValue, handleAddToQuote }: AddQuoteProps) {
  const [isPending, startTransition] = useTransition();
  const [newItemName, setNewItemName] = useState('');
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const { userId } = useAuth();
  const { showNotification } = useNotification();

  const handleNewItemNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewItemName(event.target.value);
  }, []);

  const handleAddNewItem = useCallback(
    () =>
      startTransition(async () => {
        const quoteId = getUID();
        await mutate([
          {
            create: {
              _type: 'quote',
              _id: quoteId,
              name: newItemName.trim(),
              user: { _type: 'reference', _ref: userId },
              lineItems: [],
              notes: '',
            },
          },
        ])
          .then(() => handleAddToQuote?.(quoteId))
          .then(getItems)
          .then(() =>
            showNotification({
              message: 'Quote added successfully',
              severity: 'success',
            }),
          )
          .finally(() => {
            setIsAddingNewItem(false);
          });
      }),
    [newItemName, userId, getItems, showNotification, handleAddToQuote],
  );

  if (!isAddingNewItem)
    return (
      <ListItemButton
        onClick={() => {
          setIsAddingNewItem(true);
          setSelectedValue('');
          setNewItemName('');
        }}
        selected
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>

        <ListItemText primary='Create a new project' />
      </ListItemButton>
    );

  return (
    <Stack direction='row' sx={{ py: 0.4 }}>
      <TextField
        fullWidth
        placeholder='Enter new project name'
        variant='outlined'
        value={newItemName}
        onChange={handleNewItemNameChange}
        size='small'
        autoFocus
        disabled={isPending}
      />
      <IconButton onClick={() => setIsAddingNewItem(false)} color='error' disabled={isPending}>
        <CancelIcon />
      </IconButton>
      <IconButton
        onClick={handleAddNewItem}
        color='success'
        disabled={!newItemName.trim()}
        loading={isPending}
      >
        <CreateIcon />
      </IconButton>
    </Stack>
  );
}
