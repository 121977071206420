import { useState, useEffect, useCallback } from 'react';
import { Item } from '@/components/Quotes';
import { quote } from '@/constants/fragments';
import useDialog from '@/hooks/useDialog';
import get from '@/lib/sanity/get';
import useAuth from '@/hooks/useAuth';

export default function useQuote() {
  const [selectedValue, setSelectedValue] = useState('');
  const [items, setItems] = useState<Item[]>([]);
  const { userId, setOpenLogin } = useAuth();
  const { open, handleClick, handleClose, CloseButton } = useDialog();

  const getItems = useCallback(async () => {
    if (!userId) return;

    get({
      query: quote,
      cache: false,
    }).then(setItems);
  }, [userId]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleClickAddToQuote = useCallback(() => {
    if (!userId) {
      setOpenLogin(true);
      return;
    }

    return handleClick();
  }, [handleClick, setOpenLogin, userId]);

  return {
    userId,
    items,
    selectedValue,
    setSelectedValue,
    open,
    getItems,
    handleClick,
    handleClose,
    CloseButton,
    handleClickAddToQuote,
  };
}
