'use client';

import { useCallback } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface Item {
  id: string;
  name: string;
  createdAt: string;
  quantity: number;
}

interface QuotesProps {
  items: Item[];
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function Quotes({ items, selectedValue, setSelectedValue }: QuotesProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedValue(event.target.value);
    },
    [setSelectedValue],
  );

  return (
    <div>
      <RadioGroup
        aria-label='projects'
        name='projects'
        value={selectedValue}
        onChange={handleChange}
      >
        <List>
          {items.map((item) => (
            <ListItem key={item.id}>
              <FormControlLabel
                value={item.id}
                control={<Radio sx={{ mr: 1 }} />}
                label={
                  <ListItemText
                    primary={item.name}
                    secondary={`${new Date(item.createdAt).toLocaleDateString()} · ${item.quantity} items`}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </RadioGroup>
    </div>
  );
}
